import React from "react"
import Zoom from "react-reveal/Zoom"

function SideBySide({
  title,
  description,
  children,
  imageSource,
  direction = "left",
  last = false,
}) {
  return (
    <div className={`container spot-container ${last ? "last" : ""}`}>
      <div className={`spot-content image-${direction}`}>
        {imageSource && (
          <div className="spot-image">
            <Zoom>
              <img src={imageSource} alt={title} />
            </Zoom>
          </div>
        )}
        <div className="spot-copy">
          {title && (
            <>
              <h2>{title}</h2>
              <p>{description}</p>
            </>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default SideBySide
