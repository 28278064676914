import React from "react"

function HomeHeadline({ title }) {
  return (
    <div className="container home-headline">
      <div className="row">
        <div className="tweleve columns">
          <h2>{title}</h2>
        </div>
      </div>
    </div>
  )
}

export default HomeHeadline
