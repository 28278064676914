export const cities = [
  {
    id: "spot3-augusta-production",
    name: "Augusta",
    color: "#592925",
    coordinates: [-81.96861, 33.47108],
    variant: "bottom-left",
    live: false,
  },
  {
    id: "spot3-austin-production",
    name: "Austin",
    color: "#008b43",
    icon:
      "https://res.cloudinary.com/spot-moto/image/upload/v1500158763/austin/shared/austin-avatar.png",
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342889/tenants/hero/austin.png",
    coordinates: [-97.747091, 30.26491],
    variant: "bottom-left",
    live: true,
  },
  {
    id: "spot3-boston-production",
    name: "Boston",
    color: "#FDB71A",
    icon:
      "https://res.cloudinary.com/spot-boston/image/upload/v1499864000/boston/shared/boston-avatar.png",
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342890/tenants/hero/boston.png",
    coordinates: [-71.057967, 42.360322],
    variant: "top-right",
    live: true,
  },
  {
    id: "spot3-calgary-production",
    name: "Calgary",
    color: "#000000",
    hero:
      "https://spot-moto-res.cloudinary.com/image/upload/v1505374589/calgary/shared/calgary-hero.png",
    coordinates: [-114.06311, 51.044482],
    variant: "top-left",
    live: true,
  },
  {
    id: "spot3-chicago-production",
    name: "Chicago",
    color: "#5a7aa0",
    icon:
      "https://res.cloudinary.com/spot-moto/image/upload/v1500158763/chicago/shared/chicago-avatar.png",
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342889/tenants/hero/chicago.png",
    coordinates: [-87.627778, 41.881944],
    variant: "top-right",
    live: true,
  },
  {
    id: "spot3-edmonton-production",
    name: "Edmonton",
    color: "#154C7C",
    icon: null,
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342894/tenants/hero/edmonton.png",
    coordinates: [-113.482962, 53.590127],
    variant: "top-left",
    live: true,
  },
  {
    id: "spot3-fort-worth-production",
    name: "Fort Worth",
    color: "#861f41",
    icon:
      "https://spot-moto-res.cloudinary.com/image/upload/v1516242964/fort-worth/shared/fort-worth-avatar.png",
    hero:
      "https://spot-moto-res.cloudinary.com/image/upload/c_scale,w_401/v1516243386/fort-worth/shared/fort-worth-hero.png",
    coordinates: [-97.316105, 32.809392],
    variant: "bottom-left",
    live: true,
  },
  {
    id: "spot3-little-rock-production",
    name: "Little Rock",
    color: "#0054a4",
    icon:
      "https://res.cloudinary.com/spot-moto/image/upload/v1500158763/little-rock/shared/little-rock-avatar.png",
    hero:
      "https://res.cloudinary.com/spot-moto/image/upload/v1500158802/little-rock/shared/little-rock-hero.png",
    coordinates: [-92.357323, 34.725397],
    variant: "bottom-right",
    live: true,
  },
  {
    id: "spot3-newport-news-production",
    name: "Newport News",
    color: "#255eac",
    coordinates: [-76.430061, 36.977684],
    hero:
      "https://spot-moto-res.cloudinary.com/image/upload/v1562182761/newport-news/shared/newport-news-hero.png",
    variant: "bottom-right",
    live: false,
  },
  {
    id: "spot3-san-francisco-production",
    name: "San Francisco",
    color: "#4568A2",
    icon:
      "https://res.cloudinary.com/spot-sf/image/upload/v1500158209/san-francisco/shared/san-francisco-avatar.png",
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342894/tenants/hero/san_francisco.png",
    coordinates: [-122.4183, 37.775],
    variant: "bottom-left",
    live: true,
  },
  {
    id: "spot3-san-diego-production",
    name: "San Diego",
    color: "#0098db",
    icon:
      "https://res.cloudinary.com/spot-moto/image/upload/v1500158763/little-rock/shared/little-rock-avatar.png",
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342893/tenants/hero/san_diego.png",
    coordinates: [-117.16276879999998, 32.7168846],
    variant: "bottom-left",
    live: true,
  },
  {
    id: "spot3-seattle-production",
    name: "Seattle",
    color: "#007DC3",
    icon:
      "https://res.cloudinary.com/spot-moto/image/upload/v1498769466/seattle/shared/seattle-avatar.png",
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342894/tenants/hero/seattle.png",

    coordinates: [-122.335, 47.616],
    variant: "top-left",
    live: false,
  },
  {
    id: "spot3-windsor-production",
    name: "Windsor",
    color: "#153348",
    hero:
      "https://spotreporters-res.cloudinary.com/image/upload/v1557342894/tenants/hero/windsor.png",
    coordinates: [-83.025091, 42.296307],
    variant: "top-right",
    live: true,
  },
]
