import React from "react"

function Footer() {
  return (
    <footer className="container">
      <div className="row">
        <div className="footer">
          <p>
            Copyright © 2009-{new Date().getFullYear()}, Connected Bits, LLC
          </p>
          <p className="social">
            <a href="https://www.facebook.com/connectedbits">
              <i className="demo-icon icon-facebook-official" />
            </a>{" "}
            <a href="https://twitter.com/connectedbits">
              <i className="demo-icon icon-twitter" />
            </a>{" "}
            <a href="https://vimeo.com/user10781016">
              <i className="demo-icon icon-vimeo" />
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
