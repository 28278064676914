import gql from "graphql-tag"

const queryReports = gql`
  query reports($tenantID: ID!) {
    tenant(id: $tenantID) {
      reports(pageSize: 10, blacklisted: false) {
        reports {
          id
          ticket
          serviceID
          serviceName
          shared
          blacklisted
          status
          statusLabel
          statusMessage
          statusUpdatedAt
          openedAt
          closedAt
          description
          channel
          submittedPhoto
          closedPhoto
          latitude
          longitude
          address
        }
      }
    }
  }
`
export default queryReports
