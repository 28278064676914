import React from "react"
import SideBySide from "./SideBySide"
import { Link } from "gatsby"

function SideByNav({
  title,
  logoSource,
  tagline,
  imageSource,
  to,
  toLabel,
  direction = "left",
  last = false,
}) {
  return (
    <SideBySide imageSource={imageSource} direction={direction} last={last}>
      <img src={logoSource} alt={title} />
      <p>{tagline}</p>
      <Link to={to} className="button button-primary">
        {toLabel}
      </Link>
    </SideBySide>
  )
}

export default SideByNav
