import { Link } from "gatsby"
import React from "react"
import logo from "../images/cb-logo.svg"

function TopNav() {
  function handleMenuClick(event) {
    event.preventDefault()
    var x = document.getElementById("myTopnav")
    if (x.className === "topnav") {
      x.className += " responsive"
    } else {
      x.className = "topnav"
    }
  }

  return (
    <div className="topnav" id="myTopnav">
      <div className="container">
        <div className="row">
          <a href="/" className="logo" onClick={handleMenuClick}>
            <img src={logo} alt="Connected Bits" />
          </a>
          <div className="menu">
            <a href="/" className="icon" onClick={handleMenuClick}>
              <i className="demo-icon icon-menu" />
            </a>
            <div className="menu-items">
              <Link to="/" activeClassName="active">
                Home
              </Link>
              <Link to="/reporters/" activeClassName="active">
                Spot Reporters
              </Link>
              <Link to="/workers/" activeClassName="active">
                Spot Workers
              </Link>
              <Link to="/contact/" activeClassName="active">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopNav
