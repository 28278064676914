import React from "react"

function HomeCitySlide({ city }) {
  const { name } = city
  return (
    <div className="city-image" key={name}>
      <img src={city.hero} alt={name} />
      <div className="city">{name}</div>
    </div>
  )
}

export default HomeCitySlide
