import React from "react"
import {
  ComposableMap,
  Geographies,
  Geography,
  Markers,
  ZoomableGroup,
} from "react-simple-maps"
import LiveMapCityMarker from "./LiveMapCityMarker"
import LiveMapSpotMarker from "./LiveMapSpotMarker"

const width = 800
const height = 600
const projectionConfig = {
  scale: 800,
  rotation: [0, 0, 0],
}

const center = [-95, 41]
const include = ["USA", "CAN", "MEX"]

function Map({ cities, activeCity }) {
  return (
    <div style={styles.container}>
      <ComposableMap
        projectionConfig={projectionConfig}
        width={width}
        height={height}
        style={styles.map}
      >
        <ZoomableGroup center={center} disablePanning>
          <Geographies geography="world-50m.json">
            {(geographies, projection) =>
              geographies.map((geography, i) => {
                const geographyStyle = {
                  ...styles.geography,
                  ...{
                    fill: `${geography.id === "USA" ? "#6BABD7" : "#93C7EA"}`,
                  },
                }
                return (
                  include.indexOf(geography.id) !== -1 && (
                    <Geography
                      key={i}
                      geography={geography}
                      projection={projection}
                      style={{
                        default: geographyStyle,
                        hover: geographyStyle,
                        pressed: geographyStyle,
                      }}
                    />
                  )
                )
              })
            }
          </Geographies>
          <Markers>
            {cities.map((c, i) => (
              <LiveMapCityMarker
                city={c}
                selected={activeCity && activeCity.id === c.id}
                marker={c}
                key={c.id}
                top={c.id === "windsor"}
              />
            ))}
          </Markers>
          {activeCity && (
            <LiveMapSpotMarker size={44} subject={activeCity.coordinates} />
          )}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  )
}

const styles = {
  container: {
    maxWidth: "100%",
    height: "auto",
    margin: 0,
    marginTop: 0,
  },
  map: {
    width: "100%",
    height: "auto",
    marginBottom: -6,
  },
  geography: {
    stroke: "#95CBEE",
    strokeWidth: 0.75,
    outline: "none",
  },
}

export default Map
