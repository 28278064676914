import { Link } from "gatsby"
import React from "react"

function Ready({ file, title }) {
  return (
    <div className="ready">
      <div className="container">
        <div className="row ready-container">
          <div className="ready-content">
            <h4>Ready to learn more?</h4>
            <p>
              <a href={file}>{title}</a>
            </p>
          </div>
          <div className="ready-button">
            <Link to="/contact/" className="button button-primary">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ready
