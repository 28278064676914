import React from "react"
import { Marker } from "react-simple-maps"

class CityMarker extends React.Component {
  handleMouseEnter(marker, event) {
    const { onMouseEnter } = this.props
    if (onMouseEnter) {
      onMouseEnter(marker, event)
    }
  }

  handleClick(marker, event) {
    const { onClick } = this.props
    if (onClick) {
      onClick(marker, event)
    }
  }

  refCallback = element => {
    if (element) {
      const { city, getPosition } = this.props
      const rect = element.getBoundingClientRect()
      if (getPosition) {
        getPosition(city, rect)
      }
    }
  }

  render() {
    const { city, selected, top } = this.props
    const orange = "#f58a46"
    const gray = "#3B3B3B"
    const blue = "#0073A2"
    return (
      <Marker
        id={`marker-${city.id}`}
        marker={city}
        style={{
          default: { stroke: gray },
          hover: { stroke: orange },
          pressed: { stroke: orange },
        }}
        onMouseEnter={this.handleMouseEnter.bind(this)}
        onClick={this.handleClick.bind(this)}
        {...this.props}
      >
        <circle
          cx={0}
          cy={0}
          r={3}
          ref={this.refCallback}
          style={{
            stroke: blue,
            fill: selected ? blue : blue,
            strokeWidth: 3,
          }}
        />
        <text
          textAnchor="middle"
          y={top ? -12 : 22}
          style={{
            stroke: blue,
            fill: blue,
            fontSize: 18,
            fontWeight: "normal",
          }}
        >
          {city.name}
        </text>
      </Marker>
    )
  }
}

export default CityMarker
