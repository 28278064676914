import React from "react"

const foregroundMap = {
  submitted: "#607d8b",
  open: "#4caf50",
  closed: "#2196f3",
}

const backgroundMap = {
  submitted: "#eceff1",
  open: "#e8f5e9",
  closed: "#e3f2fd",
}

function StatusBadge({ status, label }) {
  const title = label || status
  const color = foregroundMap[status]
  const backgroundColor = backgroundMap[status]
  return (
    <span
      style={{
        ...styles.container,
        ...{ border: `1px solid ${color}`, color, backgroundColor },
      }}
    >
      {title.toUpperCase()}
    </span>
  )
}

const styles = {
  container: {
    display: "inline",
    backgroundColor: "#ebf4ea",
    borderRadius: 4,
    border: "1px solid #68ac5b",
    color: "#68ac5b",
    padding: "0 0.5rem",
    margin: "0 0.5rem 0 0",
  },
}

export default StatusBadge
