import React from "react"
import StatusBadge from "./StatusBadge"
import TimeAgo from "./TimeAgo"
import Zoom from "react-reveal/Zoom"
import ios from "../images/channel-ios.svg"
import android from "../images/channel-android.svg"
import web from "../images/channel-web.svg"
import call from "../images/channel-call.svg"

function LiveMapBalloon({ style, city, report }) {
  let photo = city.hero
  if (report && report.closedPhoto) {
    photo = report.closedPhoto
  } else if (report.submittedPhoto) {
    photo = report.submittedPhoto
  }

  function iconForChannel(channel) {
    switch (channel) {
      case "iphone":
        return ios
      case "android":
        return android
      case "web":
        return web
      default:
        return call
    }
  }

  return (
    <Zoom>
      <div style={style}>
        <div style={styles.watermark}>
          {city.icon && (
            <img
              src={city.icon}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 8,
                margin: "auto",
                width: 88,
                height: 88,
                opacity: 0.2,
              }}
              alt=""
            />
          )}
        </div>
        <div style={styles.card}>
          <div
            style={{
              ...styles.thumb,
              ...{ backgroundImage: photo ? `url(${photo})` : null },
            }}
          />
          <div style={styles.content}>
            <div style={styles.name}>
              <strong>
                {report.serviceName &&
                  report.serviceName.replace(/(.{80})..+/, "$1…")}
              </strong>
            </div>
            <div style={styles.address}>
              {report.address && report.address.replace(/(.{80})..+/, "$1…")}
            </div>
            <div style={styles.status}>
              <StatusBadge status={report.status} label={report.statusLabel} />
              <TimeAgo time={report.statusUpdatedAt} twitter={true} />
              <img
                style={styles.channel}
                src={iconForChannel(report.channel)}
                alt={report.channel}
              />
            </div>
          </div>
        </div>
      </div>
    </Zoom>
  )
}

const styles = {
  container: {},
  card: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "10px",
    border: "5px solid #f79720",
    backgroundColor: "#fff",
    padding: 0,
    textAlign: "left",
    overflow: "hidden",
  },
  thumb: {
    width: 100,
    height: "auto",
    backgroundSize: "cover",
  },
  content: {
    flexGrow: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "12px",
    marginTop: "8px",
  },
  name: {
    fontSize: "1.6rem",
  },
  address: {
    marginTop: 4,
    fontSize: "1.4rem",
  },
  status: {
    marginTop: 6,
    fontSize: "1.2rem",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  channel: {
    marginLeft: 4,
  },
  watermark: {
    height: "100%",
  },
}

export default LiveMapBalloon
