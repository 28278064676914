import React from "react"
import sap from "../images/sap.png"
import motorola from "../images/motorola.png"
import verint from "../images/verint.png"
import salesforce from "../images/salesforce.png"
import microsoft from "../images/microsoft.png"

function HomeFlexible() {
  return (
    <div className="container flexible">
      <h2>Flexible</h2>
      <p>
        Our APIs can be used to integrate seamlessly with just about any system.
      </p>
      <div className="flexible-content">
        <img src={sap} alt="SAP" />
        <img src={motorola} alt="Motorola" />
        <img src={verint} alt="Verint" />
        <img src={salesforce} alt="Salesforce" />
        <img src={microsoft} alt="Microsoft" />
        <p className="more">
          And many
          <br />
          more!
        </p>
      </div>
    </div>
  )
}

export default HomeFlexible
