import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import useInterval from "../lib/useInterval"
import queryReports from "../graphql/queryReports"
import { cities } from "../data/cities"
import LiveMap from "./LiveMap"
import LiveMapBalloon from "./LiveMapBalloon"
import background from "../images/map-background.png"
import Pulse from "react-reveal/Pulse"
import useWindowSize from "../lib/useWindowSize"

const pulse = 8000

function HomeHero() {
  const size = useWindowSize()

  const [payload, setPayload] = useState({
    city: getRandomCity(),
    report: null,
  })

  useInterval(() => {
    setPayload({ city: getRandomCity(), report: null })
  }, pulse)

  const { data, error } = useQuery(queryReports, {
    variables: { tenantID: payload.city.id },
    skip: payload.city && payload.report,
    fetchPolicy: "network-only",
  })

  if (error) {
    console.error(error)
  } else if (
    data &&
    data.tenant &&
    data.tenant.reports &&
    data.tenant.reports.reports &&
    data.tenant.reports.reports.length > 0
  ) {
    const report = getRandomReport(data.tenant.reports.reports)
    if (report) {
      setPayload({ city: payload.city, report })
    }
  }

  function getRandomCity() {
    const liveCities = cities.filter(c => c.live)
    return liveCities[Math.floor(Math.random() * liveCities.length)]
  }

  function getRandomReport(reports) {
    return reports[Math.floor(Math.random() * reports.length)]
  }

  return (
    <div className="hero">
      <div className="container" style={{ padding: 0 }}>
        <div style={styles.container}>
          <LiveMap
            cities={cities}
            activeCity={payload.city && payload.report ? payload.city : null}
            style={{ width: "100%" }}
          />
          <div style={styles.header}>
            <h3 style={{ paddingBottom: 0, marginBottom: 0 }}>
              Live from the Streets...
            </h3>
            {size.width >= 750 && (
              <Pulse>
                <h6 style={{ fontSize: "2.0rem" }}>
                  Reporters and Workers Collaborating
                </h6>
              </Pulse>
            )}
          </div>
          <div style={styles.toast}>
            {payload.city && payload.report && (
              <LiveMapBalloon city={payload.city} report={payload.report} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    background: `#bfe1f7 url(${background}) center center`,
    backgroundSize: "cover",
    backgroundClip: "content-box",
    marginBottom: "4rem",
    marginTop: 83,
  },
  container: {
    width: "100%",
    position: "relative",
  },
  header: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 16,
    width: "100%",
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 4,
    width: "100%",
    textAlign: "center",
  },
  toast: {
    position: "absolute",
    minWidth: 320,
    left: "50%",
    bottom: 16,
    transform: "translate(-50%, 0)",
  },
}

export default HomeHero
