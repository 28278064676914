import React from "react"
import * as moment from "moment"

const weekMils = 604800000
const dayMils = 86400000
const hourMils = 3600000
const minMils = 60000

export default class TimeAgo extends React.PureComponent {
  static defaultProps = {
    hideAgo: false,
    interval: 60000,
    twitter: false,
  }

  scheduleNextTimer = false

  constructor(props) {
    super(props)
    this.state = { agoString: this.getTimeAgo() }
  }

  componentWillMount() {
    this.scheduleNextTimer = true
  }

  componentDidMount() {
    this.createTimer()
  }

  componentWillUnmount() {
    this.scheduleNextTimer = false
  }

  render() {
    return <span>{this.state.agoString}</span>
  }

  createTimer = () => {
    setTimeout(this.update, this.props.interval)
  }

  update = () => {
    if (this.scheduleNextTimer) {
      this.setState({ agoString: this.getTimeAgo() })
      this.createTimer()
    }
  }

  getTimeAgo() {
    const { time, twitter, hideAgo } = this.props
    if (twitter) {
      const thisMoment = moment(time)
      if (thisMoment.toDate().getTime() === 0) {
        return "epoch"
      }
      let diff = thisMoment.diff(moment())
      diff = Math.abs(diff)
      if (diff > weekMils) {
        return Math.round(diff / weekMils) + "w ago"
      } else if (diff > dayMils) {
        return Math.round(diff / dayMils) + "d ago"
      } else if (diff > hourMils) {
        return Math.round(diff / hourMils) + "h ago"
      } else if (diff > minMils) {
        return Math.round(diff / minMils) + "m ago"
      } else {
        return "just now"
      }
    } else {
      return moment(time).fromNow(hideAgo)
    }
  }
}
