import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import SideByNav from "../components/SideByNav"
import HomeCityCarousel from "../components/HomeCityCarousel"
import HomeHero from "../components/HomeHero"
import HomeHeadline from "../components/HomeHeadline"
import HomeDNA from "../components/HomeDNA"
import Ready from "../components/Ready"
import HomeFlexible from "../components/HomeFlexible"

function HomePage() {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allProductHighlightsYaml {
        nodes {
          imageSource {
            publicURL
          }
          logoSource {
            publicURL
          }
          direction
          id
          tagline
          title
          to
          toLabel
          last
        }
      }
    }
  `)

  const title = "Home"
  const headline = "Two products that bring reporters and workers together."
  const highlights = data.allProductHighlightsYaml.nodes

  return (
    <Layout>
      <SEO title={title} />
      <HomeHero />
      <HomeHeadline title={headline} />
      {highlights.map(highlight => (
        <SideByNav
          key={highlight.id}
          title={highlight.title}
          imageSource={highlight.imageSource.publicURL}
          direction={highlight.direction}
          logoSource={highlight.logoSource.publicURL}
          tagline={highlight.tagline}
          to={highlight.to}
          toLabel={highlight.toLabel}
          last={highlight.last}
        />
      ))}
      <HomeFlexible />
      <HomeCityCarousel />
      <HomeDNA />
      <Ready
        file="/spot-mobile.pdf"
        title="Download Spot Mobile Overview (PDF)"
      />
    </Layout>
  )
}

export default HomePage
