import React from "react"
import Carousel from "nuka-carousel"
import HomeCitySlide from "./HomeCitySlide"
import { cities } from "../data/cities"

function HomeCityCarousel() {
  return (
    <div className="cities">
      <div>
        <h2>As seen in these innovative cities</h2>
        <Carousel
          className="carousel"
          withoutControls={true}
          autoGenerateStyleTag={false}
          autoplay={true}
          autoplayInterval={2500}
          speed={2000}
          slidesToShow={4}
          slidesToScroll={1}
          pauseOnHover={true}
          centerMode={true}
          cellAlign={"center"}
          wrapAround={true}
          initialSlideHeight={500}
          slideWidth={"350px"}
        >
          {cities
            .filter(city => city.hero)
            .map(city => (
              <HomeCitySlide city={city} key={city.name} />
            ))}
        </Carousel>
        <div className="container">
          <div className="row">
            <div className="city-quote">
              <h3>
                "It helps the city crowd-source the greatest concerns of the
                community."
              </h3>
              <p>Katie K., San Diego</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCityCarousel
