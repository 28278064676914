import React from "react"
import screenshot from "../images/mobile-dna.png"
import wiredLogo from "../images/wired-logo.svg"
import Fade from "react-reveal/Fade"

function HomeDNA() {
  return (
    <div className="container content-block">
      <h2>Mobile is in our DNA</h2>
      <div className="content-block-container">
        <div className=" content-block-left">
          <p>
            Connected Bits has been at the forefront of enterprise mobile
            technology for over 15 years. Our proven applications have been used
            by millions of users worldwide.
          </p>
          <p>
            From the team that brought you{" "}
            <a href="http://www.streetbump.org">Street Bump</a>, as featured in{" "}
            <a href="https://www.wired.com/insights/2014/03/potholes-big-data-crowdsourcing-way-better-government/">
              <img src={wiredLogo} alt="Wired" style={{ height: ".9em" }} />
            </a>
            .
          </p>
        </div>
        <div className=" content-block-right">
          <Fade bottom>
            <img src={screenshot} alt="Mobile DNA" />
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default HomeDNA
