import React, { Component } from "react"
import spotIcon from "../images/spot-icon-alt.svg"

class LiveMapSpotMarker extends Component {
  render() {
    const { projection, subject, style, zoom, size } = this.props

    const translation = projection(subject)

    const dx = (size / 5) * -1
    const dy = size * -1

    return (
      <g
        className="rsm-annotation"
        style={style}
        transform={`translate(
          ${translation[0] + dx / zoom}
          ${translation[1] + dy / zoom}
        )`}
      >
        <svg>
          <image href={spotIcon} x="0" y="0" height="44px" width="44px" />
        </svg>
      </g>
    )
  }
}

LiveMapSpotMarker.defaultProps = {
  componentIdentifier: "Annotation",
  zoom: 1,
}

export default LiveMapSpotMarker
